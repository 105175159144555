import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CotizadorMainProvider from '../context/CotizadorMainContext';
import CotizadorMainLayout from '../layout/CotizadorMainLayout';
import CotizadorLoginPage from '../pages/login/CotizadorLoginPage';
import CotizadorAccountForm from '../pages/register/CotizadorRegisterPage';
import CotizadorPrivate from './CotizadorPrivateRoute';
import ProductsPage from '../pages/products/ProductsPage';

const CotizadorRouter = (): JSX.Element => {
  return (
    <CotizadorMainProvider>
      <CotizadorMainLayout>
        <Routes>
          <Route path='/login' element={<CotizadorLoginPage />} />
          <Route path='/register' element={<CotizadorAccountForm />} />
          <Route
            path='/home'
            element={
              <CotizadorPrivate>
                <ProductsPage />
              </CotizadorPrivate>
            }
          />
          <Route
            path='*'
            element={
              <CotizadorPrivate>
                <ProductsPage />
              </CotizadorPrivate>
            }
          />
        </Routes>
      </CotizadorMainLayout>
    </CotizadorMainProvider>
  );
};

export default CotizadorRouter;
