import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import axios from 'axios';
import * as Yup from 'yup';
import { ErcoProduct, ProductConditions } from '../../interfaces/products';
import { useCotizadorProvider } from '../../context/CotizadorMainContext';
import { CRUDModes } from '../../../../interfaces/CRUD';
import { AlertTypes } from '../../../../interfaces/MainContextInitialValues';
import { getBase64 } from '../../../../utils/fns';
import DialogCustomTitle from '../../../../components/DialogCustomTitle/DialogCustomTitle';
import CenteredForm from '../../../../index.styled';
import { PrimarySubtitle } from '../../../../components/PrimarySubtitle/PrimarySubtitle';
import { PrimaryDescription } from '../../../../components/PrimaryDescription/PrimaryDescription';
import { DisclaimerText } from '../../../../components/DisclaimerText/DisclaimerText';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import { CMTextfield } from '../../../../components/Forms/CMTextfield';
import { CMInputLabel } from '../../../../components/Forms/InputLabel';
import { CMTextArea } from '../../../../components/Forms/CMTextArea';
import { StyledButton } from '../../../../components/PrimaryButton/PrimaryButton.Styled';
import { mainTheme } from '../../../../utils/theme/theme';
import NoImage from '../../../../assets/img/no-image.jpg';
import { FlexContainer } from '../../../../components/FlexContainer/FlexContainer';
import {
  EmailMessage,
  MinValidationMessage,
  RequiredMessage,
} from '../../../../utils/validationMessages';

interface FormProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  mode: CRUDModes;
  values?: ErcoProduct;
  onSuccess: () => void;
  closeFunction?: () => void;
  onInvoice: () => void;
}

const initialValues: ErcoProduct = {
  id: 0,
  accountId: 0,
  is_active: true,
  fecha: '',
  name: '',
  imageURL: '',
  description: '',
  accesories: '',
  model: '',
  warranty: '',
  year: 0,
  condition: ProductConditions.unknown,
  timeForShipping: '',
  price: 0,
};

function ErcoProductForm({
  open,
  setOpen,
  mode,
  values,
  onSuccess,
  closeFunction,
  onInvoice,
}: FormProps): JSX.Element {
  const {
    loading,
    setLoading,
    setAlertMessage,
    setOpenAlert,
    setAlertType,
    user,
    handleLogoutError,
    token,
  } = useCotizadorProvider();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [formValues, setFormValues] = useState(initialValues);
  const [transformedFile, setTransformedFiles] = useState<string | ArrayBuffer | null>(
    null,
  );
  const [error, setError] = useState<string>('');
  const [fileTitle, setFileTitle] = useState<string>('');
  const [fileExtensions, setFileExtension] = useState<string>('');
  const fieldsDisabled = false;

  useEffect(() => {
    if (values) {
      setFormValues(values);
    }
  }, [values]);

  const validationSchema = Yup.object({
    price: Yup.number().min(1, MinValidationMessage(1)).required(RequiredMessage),
    name: Yup.string().required(RequiredMessage),
  });

  const handleUpload = async (e: any) => {
    try {
      const maximumSize = 10 * 1024 * 1024; // In MegaBytes
      if (e.target.files && e.target.files.length > 0) {
        if (e.target.files[0].size > maximumSize) {
          setAlertType(AlertTypes.Error);
          setAlertMessage('El peso del archivo es mayor al permitido');
          setOpenAlert(true);
          return;
        }
        const base64 = await getBase64(e.target.files[0]);
        setTransformedFiles(base64);
        setFileTitle(e.target.files[0].name);
        setFileExtension(e.target.files[0].type.split('/')[1]);
      }
    } catch (err) {
      handleLogoutError(err);
      setAlertType(AlertTypes.Error);
      setAlertMessage('Error al subir el archivo');
      setOpenAlert(true);
    }
  };

  const formik = useFormik<ErcoProduct>({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (productValues) => {
      try {
        if ((!transformedFile || !fileExtensions) && !productValues.imageURL) {
          setError('Por favor sube los archivos requeridos');
          return;
        }
        setError('');
        setLoading(true);
        await axios.post<
          {
            product: ErcoProduct;
            file: string | ArrayBuffer | null;
            extension: string;
          },
          ErcoProduct
        >(
          `${process.env.REACT_APP_SERVER_URL}/erco/${
            mode === CRUDModes.Create ? 'save' : 'update'
          }-product`,
          {
            product: {
              ...productValues,
              fecha: moment
                .utc()
                .utcOffset(-300)
                .toISOString(true)
                .slice(0, 19)
                .replace('T', ' '),
            },
            file: transformedFile,
            extension: fileExtensions,
          },
          {
            headers: { 'access-token': token ?? '' },
          },
        );
        setLoading(false);
        setAlertType(AlertTypes.Success);
        setAlertMessage(`Información guardada con éxito`);
        setOpenAlert(true);
        formik.resetForm();
        setError('');
        setFormValues(initialValues);
        setTransformedFiles(null);
        setFileTitle('');
        setFileExtension('');
        setOpen(false);
        onSuccess();
        if (closeFunction) closeFunction();
      } catch (e: any) {
        handleLogoutError(e);
        if (JSON.stringify(e.response.data)) {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(
            `Error al guardar producto: ${JSON.stringify(e.response.data)} `,
          );
          setOpenAlert(true);
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error en el servidor: ${e}`);
          setOpenAlert(true);
        }
      }
    },
  });

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          formik.resetForm();
          setError('');
          setFormValues(initialValues);
          setTransformedFiles(null);
          setFileTitle('');
          setFileExtension('');
          setOpen(false);
          if (closeFunction) closeFunction();
        }
      }}
    >
      <DialogCustomTitle
        onClose={() => {
          formik.resetForm();
          setError('');
          setFormValues(initialValues);
          setTransformedFiles(null);
          setFileTitle('');
          setFileExtension('');
          setOpen(false);
          if (closeFunction) closeFunction();
        }}
        mode={mode}
        title='Entrada'
      />

      <DialogContent>
        <AnimatePresence exitBeforeEnter>
          {' '}
          <FormikProvider value={formik}>
            <CenteredForm onSubmit={formik.handleSubmit}>
              <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={1}
              >
                <Grid item xs={12} md={mode === CRUDModes.Update ? 6 : 12}>
                  <PrimarySubtitle mobile>
                    {mode === CRUDModes.Update ? 'Editar' : 'Agregar nuevo'} producto
                  </PrimarySubtitle>
                  <PrimaryDescription mobile style={{ margin: '0 0 1rem 0' }}>
                    Recuerda llenar la información correctamente
                  </PrimaryDescription>
                </Grid>
                {mode === CRUDModes.Update && (
                  <Grid item xs={12} md={6} style={{ textAlign: 'end' }}>
                    <PrimaryButton loading={false} type='button' onClick={onInvoice}>
                      Generar cotización
                    </PrimaryButton>
                  </Grid>
                )}

                <Grid item xs={12} md={6}>
                  <FlexContainer direction='column'>
                    <img
                      src={
                        (transformedFile as string) || formik.values.imageURL || NoImage
                      }
                      alt='Imagen del producto'
                      style={{
                        width: mobile ? '100%' : '50%',
                        marginBottom: '1rem',
                        aspectRatio: '1 / 1',
                        objectFit: 'cover',
                      }}
                    />
                    <label htmlFor='upload-photo' style={{ width: '100%' }}>
                      <input
                        style={{ display: 'none' }}
                        id='upload-photo'
                        name='upload-photo'
                        type='file'
                        accept='image/png, image/jpeg, image/jpg'
                        onChange={handleUpload}
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                      />
                      {!fieldsDisabled ? (
                        <>
                          <StyledButton
                            component='span'
                            style={{
                              width: mobile ? '100%' : 'initial',
                              marginBottom: mobile ? '1rem' : 'initial',
                            }}
                          >
                            {fileTitle || formik.values.imageURL
                              ? 'Cambiar'
                              : 'Seleccionar'}{' '}
                            archivo
                          </StyledButton>
                          {fileTitle ? (
                            <span
                              style={{
                                marginLeft: mobile ? 'initial' : '2%',
                                color: mainTheme.colors.standardText,
                                display: mobile ? 'block' : 'initial',
                              }}
                            >
                              {fileTitle}
                            </span>
                          ) : null}
                        </>
                      ) : null}
                    </label>
                    {!fieldsDisabled ? (
                      <span
                        style={{
                          color: mainTheme.colors.standardText,
                          display: mobile ? 'block' : 'initial',
                          marginTop: '1rem',
                          marginBottom: '1rem',
                        }}
                      >
                        El peso máximo del archivo es 10 MB. los formatos permitidos son
                        .png, .jpg, jpeg
                      </span>
                    ) : null}
                  </FlexContainer>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CMTextfield
                    id='name'
                    label='Título'
                    placeholder='Título'
                    value={formik.values.name}
                    name='name'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                  <CMInputLabel mobile>Descripción</CMInputLabel>
                  <CMTextArea
                    id='description'
                    name='description'
                    value={formik.values.description ?? ''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    minRows={3}
                    placeholder='Descripción'
                    style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                    disabled={fieldsDisabled}
                  />
                  <CMTextfield
                    id='price'
                    label='Precio con IVA MXN'
                    placeholder='$ MXN'
                    value={formik.values.price || null}
                    name='price'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                    type='number'
                    error={formik.touched.price && Boolean(formik.errors.price)}
                    helperText={formik.touched.price && formik.errors.price}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PrimarySubtitle mobile>Sobre el producto</PrimarySubtitle>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CMTextfield
                    id='accesories'
                    label='Accesorios'
                    placeholder='Accesorios'
                    value={formik.values.accesories}
                    name='accesories'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                  <CMTextfield
                    id='model'
                    label='Modelo'
                    placeholder='Modelo'
                    value={formik.values.model}
                    name='model'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                  <CMTextfield
                    id='year'
                    label='Año'
                    placeholder='Año'
                    value={formik.values.year || ''}
                    name='year'
                    type='number'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CMTextfield
                    select
                    id='condition'
                    label='Condición del producto'
                    value={formik.values.condition}
                    name='condition'
                    style={{ marginBottom: '1rem' }}
                    error={formik.touched.condition && Boolean(formik.errors.condition)}
                    helperText={formik.touched.condition && formik.errors.condition}
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                  >
                    <MenuItem value={ProductConditions.unknown} disabled>
                      Seleccione una opción
                    </MenuItem>
                    <MenuItem value={ProductConditions.new}>Nuevo</MenuItem>
                    <MenuItem value={ProductConditions.used}>Usado</MenuItem>
                  </CMTextfield>
                  <CMTextfield
                    id='timeForShipping'
                    label='Tiempo de entrega'
                    placeholder='Tiempo de entrega'
                    value={formik.values.timeForShipping}
                    name='timeForShipping'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                  <CMTextfield
                    id='warranty'
                    label='Garantía'
                    placeholder='Garantía'
                    value={formik.values.warranty}
                    name='warranty'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                {error ? (
                  <DisclaimerText
                    style={{ color: 'red', textAlign: 'center', marginBottom: '1rem' }}
                  >
                    {error}
                  </DisclaimerText>
                ) : null}
                <Grid item xs={12}>
                  <FlexContainer
                    direction='row'
                    justifyContent='space-around'
                    alignItems='center'
                    alignContent='center'
                    style={{ marginTop: '2rem' }}
                  >
                    <PrimaryButton
                      loading={loading}
                      type='button'
                      onClick={formik.handleSubmit}
                      style={{ width: mobile ? '100%' : '45%' }}
                    >
                      {mode === CRUDModes.Update ? 'Actualizar' : '+ Crear'}
                    </PrimaryButton>
                    {mode === CRUDModes.Update ? (
                      <PrimaryButton
                        loading={loading}
                        type='button'
                        onClick={async () => {
                          await formik.setFieldValue('is_active', false);
                          formik.handleSubmit();
                        }}
                        style={{
                          width: mobile ? '100%' : '45%',
                          background: '#6c0000',
                        }}
                      >
                        Eliminar
                      </PrimaryButton>
                    ) : null}
                  </FlexContainer>
                </Grid>
              </Grid>
            </CenteredForm>
          </FormikProvider>
        </AnimatePresence>
      </DialogContent>
    </Dialog>
  );
}

ErcoProductForm.defaultProps = {
  values: undefined,
  closeFunction: () => {},
};
export default ErcoProductForm;
