import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { FaSearch } from 'react-icons/all';
import { FlexContainer } from '../../../../components/FlexContainer/FlexContainer';
import { PageContainer } from '../../../Register/Register.styled';
import { CRUDModes } from '../../../../interfaces/CRUD';
import { ErcoProduct } from '../../interfaces/products';
import { useCotizadorProvider } from '../../context/CotizadorMainContext';
import { AlertTypes } from '../../../../interfaces/MainContextInitialValues';
import EmptyClosure from '../../../../utils/closures';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import { PrimarySubtitle } from '../../../../components/PrimarySubtitle/PrimarySubtitle';
import { NoPaddingButton } from '../../../../components/NoPaddingButton/NoPaddingButton';
import { CMTextfield } from '../../../../components/Forms/CMTextfield';
import ProductCard from './Components/ProductCard';
import ErcoProductForm from './ProductForm';
import InvoiceForm from './InvoiceForm';

const ProductsPage = (): JSX.Element => {
  const {
    setLoading,
    setAlertType,
    setAlertMessage,
    setOpenAlert,
    handleLogoutError,
    token,
  } = useCotizadorProvider();
  const muiTheme = useTheme();
  const mobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const [products, setProducts] = useState<ErcoProduct[]>();
  const [displayedProducts, setDisplayedProducts] = useState<ErcoProduct[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<number>(-1);
  const [mode, setMode] = useState<CRUDModes>(CRUDModes.Create);
  const [search, setSearch] = useState<string>('');
  const [invoiceOpen, setInvoiceOpen] = useState<boolean>(false);

  useEffect(() => {
    if (search.length > 0 && products) {
      const filteredProducts = products?.filter((product) =>
        product.name.toLowerCase().includes(search.toLowerCase()),
      );
      setDisplayedProducts(filteredProducts);
    } else {
      setDisplayedProducts(products ?? []);
    }
  }, [products, search]);

  const fetchEntries = async () => {
    try {
      setLoading(true);
      const fetchResult = await axios.get<ErcoProduct[]>(
        `${process.env.REACT_APP_SERVER_URL}/erco/products`,
        {
          headers: { 'access-token': token ?? '' },
        },
      );
      setProducts(fetchResult.data);
      setLoading(false);
    } catch (e: any) {
      handleLogoutError(e);
      if (JSON.stringify(e.response.data)) {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(
          `Error al obtener productos: ${JSON.stringify(e.response.data)} `,
        );
        setOpenAlert(true);
      } else {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error en el servidor: ${e}`);
        setOpenAlert(true);
      }
    }
  };

  // Avoid infinite loop
  const fetchCallback = useCallback(fetchEntries, [
    handleLogoutError,
    setAlertMessage,
    setAlertType,
    setLoading,
    setOpenAlert,
    token,
  ]);

  useEffect(() => {
    fetchCallback().then(EmptyClosure).catch(EmptyClosure);
  }, [fetchCallback]);

  return (
    <PageContainer>
      {selectedRow !== -1 && products && (
        <InvoiceForm
          mode={mode}
          open={invoiceOpen}
          setOpen={setInvoiceOpen}
          values={undefined}
          onSuccess={() => {
            setSelectedRow(-1);
          }}
          product={displayedProducts[selectedRow]}
          closeFunction={() => {
            setSelectedRow(-1);
          }}
        />
      )}
      <ErcoProductForm
        mode={mode}
        open={open}
        setOpen={setOpen}
        values={
          selectedRow !== -1 && products ? displayedProducts[selectedRow] : undefined
        }
        onSuccess={() => {
          setSelectedRow(-1);
          fetchCallback().then(EmptyClosure).catch(EmptyClosure);
        }}
        closeFunction={() => {
          setSelectedRow(-1);
        }}
        onInvoice={() => {
          setMode(CRUDModes.Create);
          setOpen(false);
          setInvoiceOpen(true);
        }}
      />
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-end'
        style={{ padding: '1rem 1.5rem 1.5rem 1.5rem' }}
      >
        <Grid item xs={12} md={6} style={{ marginBottom: '0.5rem' }}>
          <PrimarySubtitle mobile style={{ overflowWrap: 'break-word' }}>
            Mis productos
          </PrimarySubtitle>
          <CMTextfield
            style={{ width: '100%', margin: '0' }}
            id='outlined-basic'
            label='Buscar'
            value={search}
            name='value'
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <NoPaddingButton type='submit'>
                  <FaSearch />
                </NoPaddingButton>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ marginBottom: '0.5rem' }}>
          <FlexContainer
            direction='column'
            justifyContent='flex-end'
            alignItems='flex-end'
            alignContent='flex-end'
          >
            <PrimaryButton
              loading={false}
              onClick={() => {
                setMode(CRUDModes.Create);
                setOpen(true);
                setSelectedRow(-1);
              }}
            >
              Nuevo producto
            </PrimaryButton>
          </FlexContainer>
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignContent='flex-start'
        alignItems='flex-start'
        wrap='wrap'
        spacing={2}
      >
        {displayedProducts?.map((product, index) => {
          return (
            <ProductCard
              key={product.id}
              product={product}
              onClick={() => {
                setMode(CRUDModes.Update);
                setOpen(true);
                setSelectedRow(index);
              }}
            />
          );
        })}
        {displayedProducts?.length === 0 && (
          <Grid item xs={12}>
            <h1>Sin resultados</h1>
          </Grid>
        )}
      </Grid>
    </PageContainer>
  );
};

export default ProductsPage;
