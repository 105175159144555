import React, { useEffect } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import {
  EmailMessage,
  MinValidationMessage,
  RequiredMessage,
} from '../../../../utils/validationMessages';
import { RegisterInitialValues } from '../../../../interfaces/Register';
import { saveLocalstorageData } from '../../../../utils/localStorage';
import { AlertTypes } from '../../../../interfaces/MainContextInitialValues';
import { PageContainer } from '../../../Register/Register.styled';
import CenteredForm from '../../../../index.styled';
import { PrimaryTitle } from '../../../../components/PrimaryTitle/PrimaryTitle';
import { PrimaryDescription } from '../../../../components/PrimaryDescription/PrimaryDescription';
import { CMTextfield } from '../../../../components/Forms/CMTextfield';
import PasswordTextfield from '../../../../components/Forms/PasswordTextfield';
import { RouterLink } from '../../../../components/RouterLink/RouterLink';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import { useCotizadorProvider } from '../../context/CotizadorMainContext';
import { CotizadorUser } from '../../interfaces/cotizadorContextInterfaces';

const CotizadorLoginPage = (): JSX.Element => {
  const {
    user,
    setOpenAlert,
    setAlertMessage,
    setAlertType,
    setUser,
    setLoading,
    setToken,
  } = useCotizadorProvider();
  const navigate = useNavigate();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const validationSchema = Yup.object({
    username: Yup.string().email(EmailMessage).required(RequiredMessage),
    password: Yup.string().min(8, MinValidationMessage(8)).required(RequiredMessage),
  });

  const onSubmit = async (registerValues: RegisterInitialValues) => {
    try {
      setLoading(true);
      const loginResult = await axios.post<{
        user: CotizadorUser;
        token: string;
      }>(`${process.env.REACT_APP_SERVER_URL}/erco/login`, {
        username: registerValues.username,
        password: registerValues.password,
      });
      if (loginResult.status === 200) {
        console.log(loginResult.data.user);
        saveLocalstorageData('COTIZADOR_INFORMATION', {
          ...loginResult.data.user,
          token: loginResult.data.token,
        });
        setUser({ ...loginResult.data.user });
        setToken(loginResult.data.token);
      }
      setLoading(false);
    } catch (e: any) {
      if (JSON.stringify(e.response.data)) {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error al iniciar sesión: ${JSON.stringify(e.response.data)} `);
        setOpenAlert(true);
      } else {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error en el servidor: ${e}`);
        setOpenAlert(true);
      }
    }
  };

  const formik = useFormik<RegisterInitialValues>({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema,
    /**
     * @desc logins the user with the given information
     * @param registerValues
     */
    onSubmit,
  });

  useEffect(() => {
    if (user) {
      navigate('/erco/home');
    }
  }, [user, navigate]);

  return (
    <PageContainer>
      <Grid container direction='row' justifyContent='center'>
        <Grid item xs={12} md={5} lg={3}>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              exit={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3 }}
              key='login'
            >
              <CenteredForm onSubmit={formik.handleSubmit}>
                <PrimaryTitle mobile={mobile}>Bienvenido al cotizador</PrimaryTitle>
                <PrimaryDescription mobile={mobile}>
                  Inicia sesión con tu correo y contraseña
                </PrimaryDescription>
                <CMTextfield
                  capitalized
                  id='outlined-basic'
                  label='Email'
                  value={formik.values.username}
                  name='username'
                  style={{ marginBottom: '1rem' }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.username && Boolean(formik.errors.username)}
                  helperText={formik.touched.username && formik.errors.username}
                  onChange={formik.handleChange}
                />
                <PasswordTextfield
                  id='outlined-basic'
                  label='Contraseña'
                  variant='outlined'
                  value={formik.values.password}
                  name='password'
                  style={{ marginBottom: '1rem' }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  onChange={formik.handleChange}
                />
                <RouterLink to='/erco/register'>
                  ¿Nuevo en Erco? Crea tu cuenta
                </RouterLink>
                <PrimaryButton
                  loading={false}
                  type='submit'
                  style={{ width: '100%', marginTop: '1rem' }}
                >
                  Inciar sesión
                </PrimaryButton>
              </CenteredForm>
            </motion.div>
          </AnimatePresence>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default CotizadorLoginPage;
