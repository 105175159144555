/* eslint-disable no-restricted-syntax */

import { Patient } from '../interfaces/Patients';
import { User, UserTypes } from '../interfaces/User';
import { Doctor } from '../interfaces/Doctors';
import { Nurse } from '../interfaces/Nurses';

const sortObjectArray = <T>(objectsArray: T[], key: keyof T): T[] => {
  const arrayInformation = [...objectsArray];
  return arrayInformation.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
};

const deleteNullValues = <T>(values: T): T => {
  const objectValues = { ...values };
  for (const [key, value] of Object.entries(values)) {
    if (value === undefined || value === null || value === '' || value === -1) {
      delete objectValues[key as keyof typeof values];
    }
  }
  return objectValues;
};

const getBase64 = async (file: File | Blob): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const getPatientFullName = (initialData?: Patient): string => {
  console.log(initialData);
  if (!initialData) return '';
  console.log(
    `${initialData.pac_nombre} ${initialData.pac_apellidop} ${initialData.pac_apellidom}`,
  );
  return `${initialData.pac_nombre} ${initialData.pac_apellidop} ${initialData.pac_apellidom}`;
};

const getDoctorFullName = (initialData?: Doctor): string => {
  if (!initialData) return '';
  return `${initialData.med_nombre} ${initialData.med_apellidop} ${initialData.med_apellidom}`;
};

const getNurseFullName = (initialData?: Nurse): string => {
  if (!initialData) return '';
  return `${initialData.enf_nombre} ${initialData.enf_apellidop} ${initialData.enf_apellidom}`;
};

const padTo2Digits = (num: number): string => {
  return num.toString().padStart(2, '0');
};

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join('/');
};

const getUserImage = (user?: User): string => {
  if (user && user.initialData) {
    if (user.typeId === UserTypes.PatientType) {
      return (user.initialData as Patient).pac_imagen || '';
    }
    if (user.typeId === UserTypes.DoctorType) {
      return (user.initialData as Doctor).med_imagen || '';
    }
    return (user.initialData as Nurse).enf_imagen || '';
  }
  return '';
};

const canPrintNote = (
  patientOfNote: Patient,
  doctorIdOfNote: number,
  user: User,
  isNurse = false,
  isPatientPrintingBlocked = false,
): boolean => {
  if (
    user.typeId === UserTypes.PatientType &&
    user.initialDataFilled &&
    patientOfNote.pac_id === (user.initialData as Patient).pac_id &&
    !isPatientPrintingBlocked
  ) {
    return true;
  }
  if (!isNurse) {
    return user.typeId === UserTypes.DoctorType && doctorIdOfNote === user.id;
  }
  return user.typeId === UserTypes.NurseType && doctorIdOfNote === user.id;
};

export {
  sortObjectArray,
  deleteNullValues,
  getBase64,
  getPatientFullName,
  getUserImage,
  formatDate,
  canPrintNote,
  getDoctorFullName,
  getNurseFullName,
};
