export const formatMoney = (
  amount: number | string,
  decimalCount = 2,
  decimal = '.',
  thousands = ',',
): string => {
  let numericAmount = amount;
  if (typeof numericAmount === 'string') {
    numericAmount = parseFloat(numericAmount);
  }
  if (Number.isNaN(numericAmount)) {
    return '-';
  }
  const formatted = numericAmount.toLocaleString('en-US', {
    style: 'decimal',
    minimumFractionDigits: decimalCount,
    maximumFractionDigits: decimalCount,
  });
  const [intPart, decimalPart] = formatted.split('.');
  const intPartFormat = intPart.replace(/,/g, thousands);
  return `$${intPartFormat}${decimalPart ? decimal + decimalPart : ''}`;
};

export function IRR(providedValues: number[], providedGuess: number) {
  const values = providedValues;
  let guess = providedGuess;

  // Calculates the resulting amount
  const irrResult = (vals: number[], dates: number[], rate: number) => {
    const r = rate + 1;
    let result = vals[0];

    for (let i = 1; i < vals.length; i += 1) {
      result += vals[i] / r ** ((dates[i] - dates[0]) / 365);
    }

    return result;
  };

  // Calculates the first derivation
  const irrResultDeriv = (vals: number[], dates: number[], rate: number) => {
    const r = rate + 1;
    let result = 0;

    for (let i = 1; i < vals.length; i += 1) {
      const frac = (dates[i] - dates[0]) / 365;
      result -= (frac * vals[i]) / r ** (frac + 1);
    }

    return result;
  };

  // Initialize dates and check that values contains at least one positive value and one negative value
  const dates: number[] = [];
  let positive = false;
  let negative = false;

  for (let i = 0; i < values.length; i += 1) {
    dates[i] = i === 0 ? 0 : dates[i - 1] + 365;

    if (values[i] > 0) {
      positive = true;
    }

    if (values[i] < 0) {
      negative = true;
    }
  }

  // Return error if values does not contain at least one positive value and one negative value
  if (!positive || !negative) {
    console.log('number is not positive or negative');
    return 0;
  }

  // Initialize guess and resultRate
  guess = guess === undefined ? 0.1 : guess;
  let resultRate = guess;

  // Set maximum epsilon for end of iteration
  const epsMax = 1e-10;

  // Implement Newton's method
  let newRate;
  let epsRate;
  let resultValue;
  let contLoop = true;
  do {
    resultValue = irrResult(values, dates, resultRate);
    newRate = resultRate - resultValue / irrResultDeriv(values, dates, resultRate);
    epsRate = Math.abs(newRate - resultRate);
    resultRate = newRate;
    contLoop = epsRate > epsMax && Math.abs(resultValue) > epsMax;
  } while (contLoop);

  // Return internal rate of return
  return resultRate;
}
