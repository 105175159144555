export const thirtyFiveResidualOptions = [
  {
    upfrontPayment: 0.1,
    residualAmount: 0.35,
    months: 12,
    factor: 0.0561686010287679,
  },
  {
    upfrontPayment: 0.1,
    residualAmount: 0.35,
    months: 24,
    factor: 0.0348754716446165,
  },
  {
    upfrontPayment: 0.1,
    residualAmount: 0.35,
    months: 36,
    factor: 0.0279862563767151,
  },
  {
    upfrontPayment: 0.15,
    residualAmount: 0.35,
    months: 12,
    factor: 0.054918491914574,
  },
  {
    upfrontPayment: 0.15,
    residualAmount: 0.35,
    months: 24,
    factor: 0.0336272969815354,
  },
  {
    upfrontPayment: 0.15,
    residualAmount: 0.35,
    months: 36,
    factor: 0.0267325137220755,
  },
  {
    upfrontPayment: 0.2,
    residualAmount: 0.35,
    months: 12,
    factor: 0.0536680753472956,
  },
  {
    upfrontPayment: 0.2,
    residualAmount: 0.35,
    months: 24,
    factor: 0.0323763385506919,
  },
  {
    upfrontPayment: 0.2,
    residualAmount: 0.35,
    months: 36,
    factor: 0.0254812808710146,
  },
  {
    upfrontPayment: 0.25,
    residualAmount: 0.35,
    months: 12,
    factor: 0.0524174925202627,
  },
  {
    upfrontPayment: 0.25,
    residualAmount: 0.35,
    months: 24,
    factor: 0.0311250426465408,
  },
  {
    upfrontPayment: 0.25,
    residualAmount: 0.35,
    months: 36,
    factor: 0.0242295699140146,
  },
  {
    upfrontPayment: 0.3,
    residualAmount: 0.35,
    months: 12,
    factor: 0.0511666420382339,
  },
  {
    upfrontPayment: 0.3,
    residualAmount: 0.35,
    months: 24,
    factor: 0.0298731887126759,
  },
  {
    upfrontPayment: 0.3,
    residualAmount: 0.35,
    months: 36,
    factor: 0.0229771739593006,
  },
  {
    upfrontPayment: 0.35,
    residualAmount: 0.35,
    months: 12,
    factor: 0.0499153353434411,
  },
  {
    upfrontPayment: 0.35,
    residualAmount: 0.35,
    months: 24,
    factor: 0.0286204131760507,
  },
  {
    upfrontPayment: 0.35,
    residualAmount: 0.35,
    months: 36,
    factor: 0.021723682411325,
  },
];

export const regularPaymentOptions = [
  {
    upfrontPayment: 0.1,
    residualAmount: 0.6,
    months: 12,
    factor: 0.0380447200288195,
  },
  {
    upfrontPayment: 0.1,
    residualAmount: 0.4,
    months: 24,
    factor: 0.0333230057847606,
  },
  {
    upfrontPayment: 0.1,
    residualAmount: 0.3,
    months: 36,
    factor: 0.0288387134958794,
  },
  {
    upfrontPayment: 0.1,
    residualAmount: 0.23,
    months: 48,
    factor: 0.025977446236105,
  },
  {
    upfrontPayment: 0.1,
    residualAmount: 0.23,
    months: 60,
    factor: 0.0236902006906903,
  },
  {
    upfrontPayment: 0.15,
    residualAmount: 0.6,
    months: 12,
    factor: 0.0367976196506584,
  },
  {
    upfrontPayment: 0.15,
    residualAmount: 0.4,
    months: 24,
    factor: 0.0320822688634346,
  },
  {
    upfrontPayment: 0.15,
    residualAmount: 0.3,
    months: 36,
    factor: 0.0276044376850383,
  },
  {
    upfrontPayment: 0.15,
    residualAmount: 0.23,
    months: 48,
    factor: 0.024749446048011,
  },
  {
    upfrontPayment: 0.15,
    residualAmount: 0.23,
    months: 60,
    factor: 0.022439750525237,
  },
  {
    upfrontPayment: 0.2,
    residualAmount: 0.6,
    months: 12,
    factor: 0.0355474649519346,
  },
  {
    upfrontPayment: 0.2,
    residualAmount: 0.4,
    months: 24,
    factor: 0.0308314334168615,
  },
  {
    upfrontPayment: 0.2,
    residualAmount: 0.3,
    months: 36,
    factor: 0.0263527331965315,
  },
  {
    upfrontPayment: 0.2,
    residualAmount: 0.23,
    months: 48,
    factor: 0.0234969598291419,
  },
  {
    upfrontPayment: 0.2,
    residualAmount: 0.23,
    months: 60,
    factor: 0.021187608684746,
  },
  {
    upfrontPayment: 0.25,
    residualAmount: 0.6,
    months: 12,
    factor: 0.0342932095503417,
  },
  {
    upfrontPayment: 0.25,
    residualAmount: 0.4,
    months: 24,
    factor: 0.0295618591260596,
  },
  {
    upfrontPayment: 0.25,
    residualAmount: 0.3,
    months: 36,
    factor: 0.0251115225012922,
  },
  {
    upfrontPayment: 0.25,
    residualAmount: 0.25,
    months: 48,
    factor: 0.0220372205353,
  },
  {
    upfrontPayment: 0.25,
    residualAmount: 0.25,
    months: 60,
    factor: 0.0199713779674609,
  },
  {
    upfrontPayment: 0.3,
    residualAmount: 0.6,
    months: 12,
    factor: 0.0330470085062993,
  },
  {
    upfrontPayment: 0.3,
    residualAmount: 0.4,
    months: 24,
    factor: 0.0283291166554741,
  },
  {
    upfrontPayment: 0.3,
    residualAmount: 0.3,
    months: 36,
    factor: 0.0238474975320266,
  },
  {
    upfrontPayment: 0.3,
    residualAmount: 0.3,
    months: 48,
    factor: 0.0202357706688751,
  },
  {
    upfrontPayment: 0.3,
    residualAmount: 0.3,
    months: 60,
    factor: 0.018184154544129,
  },
  {
    upfrontPayment: 0.35,
    residualAmount: 0.6,
    months: 12,
    factor: 0.0317966226128906,
  },
  {
    upfrontPayment: 0.35,
    residualAmount: 0.4,
    months: 24,
    factor: 0.0270767014674999,
  },
  {
    upfrontPayment: 0.35,
    residualAmount: 0.35,
    months: 36,
    factor: 0.0217318212676037,
  },
  {
    upfrontPayment: 0.35,
    residualAmount: 0.35,
    months: 48,
    factor: 0.0184351716495968,
  },
  {
    upfrontPayment: 0.35,
    residualAmount: 0.35,
    months: 60,
    factor: 0.0165663192942363,
  },
  {
    upfrontPayment: 0.4,
    residualAmount: 0.6,
    months: 12,
    factor: 0.0305460757382992,
  },
  {
    upfrontPayment: 0.4,
    residualAmount: 0.4,
    months: 24,
    factor: 0.0258233116816437,
  },
  {
    upfrontPayment: 0.4,
    residualAmount: 0.4,
    months: 36,
    factor: 0.0196084023821222,
  },
  {
    upfrontPayment: 0.4,
    residualAmount: 0.4,
    months: 48,
    factor: 0.0166345176551576,
  },
  {
    upfrontPayment: 0.4,
    residualAmount: 0.4,
    months: 60,
    factor: 0.0149484961098231,
  },
  {
    upfrontPayment: 0.45,
    residualAmount: 0.6,
    months: 12,
    factor: 0.0292952368293478,
  },
  {
    upfrontPayment: 0.45,
    residualAmount: 0.45,
    months: 24,
    factor: 0.0230415751143079,
  },
  {
    upfrontPayment: 0.45,
    residualAmount: 0.45,
    months: 36,
    factor: 0.0174941204374309,
  },
  {
    upfrontPayment: 0.45,
    residualAmount: 0.45,
    months: 48,
    factor: 0.0148377899521512,
  },
  {
    upfrontPayment: 0.45,
    residualAmount: 0.45,
    months: 60,
    factor: 0.0133324990190047,
  },
  {
    upfrontPayment: 0.5,
    residualAmount: 0.6,
    months: 12,
    factor: 0.0280438583290151,
  },
  {
    upfrontPayment: 0.5,
    residualAmount: 0.5,
    months: 24,
    factor: 0.0202357074596814,
  },
  {
    upfrontPayment: 0.5,
    residualAmount: 0.5,
    months: 36,
    factor: 0.0153641377506576,
  },
  {
    upfrontPayment: 0.5,
    residualAmount: 0.5,
    months: 48,
    factor: 0.0130316632155625,
  },
  {
    upfrontPayment: 0.5,
    residualAmount: 0.5,
    months: 60,
    factor: 0.0117100008338217,
  },
];
