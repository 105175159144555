import axios from 'axios';
import { getLocalstorageData } from './localStorage';
import { UserLocalStorageInformation } from '../interfaces/User';
import { FunctionResponse } from '../interfaces/Responses';

/**
 *
 * @desc makes an axios post call with required token to the back end
 * @param url
 * @param data
 * @param handleLogout
 * @param sponsorToken
 */
export const putWithToken = async <T, E>(
  url: string,
  data: T,
  handleLogout: (e: any) => void,
  sponsorToken = false,
): Promise<FunctionResponse<E>> => {
  try {
    const token = getLocalstorageData<UserLocalStorageInformation>(
      `CODIGO_MEDICO_${sponsorToken ? 'SPONSOR' : 'USER'}_INFORMATION`,
    );
    if (token.success) {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}${url}`,
        data,
        {
          headers: { 'access-token': token.data.token },
        },
      );
      if (response.status === 200) {
        return {
          success: true,
          data: response.data,
        };
      }
      return {
        success: false,
        error: response.data,
      };
    }
    return {
      success: false,
      error: token.error,
    };
  } catch (e) {
    handleLogout(e);
    return {
      success: false,
      error: JSON.stringify(e),
    };
  }
};

/**
 *
 * @desc makes an axios post call with required token to the back end
 * @param url
 * @param data
 * @param handleLogout
 * @param sponsorToken
 */
export const postWithToken = async <T, E>(
  url: string,
  data: T,
  handleLogout: (e: any) => void,
  sponsorToken = false,
): Promise<FunctionResponse<E>> => {
  try {
    const token = getLocalstorageData<UserLocalStorageInformation>(
      `CODIGO_MEDICO_${sponsorToken ? 'SPONSOR' : 'USER'}_INFORMATION`,
    );
    if (token.success) {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}${url}`,
        data,
        {
          headers: { 'access-token': token.data.token },
        },
      );
      if (response.status === 200) {
        return {
          success: true,
          data: response.data,
        };
      }
      return {
        success: false,
        error: response.data,
      };
    }
    return {
      success: false,
      error: token.error,
    };
  } catch (e) {
    handleLogout(e);
    return {
      success: false,
      error: JSON.stringify(e),
    };
  }
};

/**
 *
 * @desc makes an axios get call with required token to the back end
 * @param url
 * @param handleLogout
 * @param sponsorToken
 */
export const gettWithToken = async <E>(
  url: string,
  handleLogout: (e: any) => void,
  sponsorToken = false,
  ercoToken = false,
): Promise<FunctionResponse<E>> => {
  try {
    let key = 'CODIGO_MEDICO_USER_INFORMATION';
    if (sponsorToken) {
      key = 'CODIGO_MEDICO_SPONSOR_INFORMATION';
    } else if (ercoToken) {
      key = 'ERCO_INFORMATION';
    }
    const token = getLocalstorageData<UserLocalStorageInformation>(key);
    if (token.success) {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}${url}`, {
        headers: { 'access-token': token.data.token },
      });
      if (response.status === 200) {
        return {
          success: true,
          data: response.data,
        };
      }
      return {
        success: false,
        error: response.data,
      };
    }
    return {
      success: false,
      error: token.error,
    };
  } catch (e) {
    handleLogout(e);
    return {
      success: false,
      error: JSON.stringify(e),
    };
  }
};

export const previewUserID = 219;
