export const RequiredMessage = 'Campo requerido';
export const EmailMessage = 'Ingresa un email válido';
export const SelectValidOption = (type: number, name: string): string =>
  `Seleccione ${type === 1 ? 'un' : 'una'} ${name} ${type === 1 ? 'valido' : 'valida'}`;
export const MinValidationMessage = (min: number): string =>
  `Debe contener mínimo ${min} caracteres`;
export const MaxValidationMessage = (max: number): string =>
  `Debe contener máximo ${max} caracteres`;
export const MinAmountMessage = (min: number): string =>
  `El valor ingresado debe ser igual o mayor a ${min}`;
