import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useCotizadorProvider } from '../context/CotizadorMainContext';

function CotizadorPrivate({ children }: { children: JSX.Element }): JSX.Element {
  const { user, initialLoad, loadInitialData, token } = useCotizadorProvider();
  const location = useLocation();

  /**
   *
   * @desc checks if user is already set in the context if
   * thats the case then checks if user already filled initial data
   */
  const authenticated = () => {
    if (!initialLoad) {
      loadInitialData();
    }

    if (user && token) {
      return true;
    }

    return false;
  };

  return !initialLoad ? (
    <></>
  ) : (
    <>
      {authenticated() ? (
        children
      ) : (
        <Navigate
          to={{
            pathname: '/erco/login',
            search: location.search,
          }}
        />
      )}
    </>
  );
}

export default CotizadorPrivate;
