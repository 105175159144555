import React from 'react';
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import {
  ErcoInvoice,
  ErcoProduct,
  FactorSheet,
  ProductConditions,
} from '../../interfaces/products';
import ErcoLogo from '../../../../assets/img/erco.png';
import { formatMoney } from '../../../../utils/money';

interface InvoicePrintableProps {
  invoice: ErcoInvoice;
  product: ErcoProduct;
  factorSheet: FactorSheet;
}

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    fontSize: 12,
  },
  header: {
    backgroundColor: '#84CDF2',
    padding: 8,
    paddingLeft: 16,
  },
  paragraph: {
    width: '100%',
    marginBottom: 8,
  },
  noMargin: {
    width: '100%',
    marginBottom: 0,
    paddingBottom: 0,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  rowEqual: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  half: {
    width: '50%',
  },
  invoiceColumn: {
    width: '25%',
  },
  headerText: {
    color: '#1A3764',
  },
  section: {
    margin: 16,
  },
  image: {
    width: '200px',
    height: '200px',
    objectFit: 'contain',
  },
  bold: {
    fontWeight: 500,
  },
});

// Create Document Component
const InvoicePrintable = ({
  product,
  invoice,
  factorSheet,
}: InvoicePrintableProps): JSX.Element => (
  <Document>
    <Page orientation='portrait' size='A4' style={styles.page}>
      <View style={[styles.row, { margin: 8 }]}>
        <Image style={{ width: 50 }} src={ErcoLogo || ''} />
      </View>
      <View style={styles.header}>
        <Text style={styles.headerText}>Datos del cliente</Text>
      </View>
      <View style={styles.section}>
        <View style={styles.row}>
          <View style={styles.half}>
            <Text style={styles.noMargin}>
              <b>Nombre:</b> {invoice.customerName} {invoice.customerFirstLastName}{' '}
              {invoice.customerSecondLastName}
            </Text>
          </View>
          <View style={styles.half}>
            <Text style={styles.paragraph}>
              <b>Correo:</b> {invoice.customerEmail}
            </Text>
            <Text style={styles.noMargin}>
              <b>Teléfono:</b> {invoice.customerPhone}
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.header}>
        <Text style={styles.headerText}>Producto</Text>
      </View>
      <View style={styles.section}>
        <View style={styles.row}>
          <View style={styles.half}>
            <Text style={styles.paragraph}>
              <b>Nombre del producto:</b> {product.name}
            </Text>
            <Text style={styles.paragraph}>
              <b>Accesorios:</b> {product.accesories}
            </Text>
            <Text style={styles.paragraph}>
              <b>Condición:</b>{' '}
              {product.condition === ProductConditions.new ? 'Nuevo' : 'Usado'}
            </Text>
            <Text style={styles.noMargin}>
              <b>Descripción:</b> {product.description}
            </Text>
          </View>
          <View style={styles.half}>
            <Text style={styles.paragraph}>
              <b>Año:</b> {product.year}
            </Text>
            <Text style={styles.paragraph}>
              <b>Modelo:</b> {product.model}
            </Text>
            <Text style={styles.paragraph}>
              <b>Tiempo de entrega:</b> {product.timeForShipping}
            </Text>
            <Text style={styles.noMargin}>
              <b>Garantía:</b> {product.warranty}
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.header}>
        <Text style={styles.headerText}>Cotización</Text>
      </View>
      <View style={styles.section}>
        <View style={styles.row}>
          <View>
            <Text>Precio del producto: {formatMoney(factorSheet.discountedPrice)}</Text>
          </View>
          <View>
            <Text>
              Pago inicial: {formatMoney(factorSheet.initialPayment.finalInitialPayment)}
            </Text>
          </View>
          <View>
            <Text>Monto a arrendar: {formatMoney(factorSheet.amountToFinance)} </Text>
          </View>
        </View>
      </View>
      <View style={styles.header}>
        <Text style={styles.headerText}>Desglose de pagos</Text>
      </View>
      <View style={styles.section}>
        <View style={styles.row}>
          <View style={styles.invoiceColumn}>
            <Text style={[styles.paragraph, styles.bold]}>Plazo a financiar</Text>
            {invoice.twelveMonths && <Text style={[styles.paragraph]}>12 meses</Text>}
            {invoice.twentyFourMonths && <Text style={styles.paragraph}>24 meses</Text>}
            {invoice.thirtySixMonths && <Text style={styles.paragraph}>36 meses</Text>}
            {invoice.fortyEightMonths && <Text style={styles.paragraph}>48 meses</Text>}
            {invoice.sixtyMonths && <Text style={styles.paragraph}>60 meses</Text>}
          </View>
          <View style={styles.invoiceColumn}>
            <Text style={[styles.paragraph, styles.bold]}>Pago inicial</Text>
            {invoice.twelveMonths && (
              <Text style={styles.paragraph}>
                {formatMoney(factorSheet.initialPayment.finalInitialPayment)}
              </Text>
            )}
            {invoice.twentyFourMonths && (
              <Text style={styles.paragraph}>
                {formatMoney(factorSheet.initialPayment.finalInitialPayment)}
              </Text>
            )}
            {invoice.thirtySixMonths && (
              <Text style={styles.paragraph}>
                {formatMoney(factorSheet.initialPayment.finalInitialPayment)}
              </Text>
            )}
            {invoice.fortyEightMonths && (
              <Text style={styles.paragraph}>
                {formatMoney(factorSheet.initialPayment.finalInitialPayment)}
              </Text>
            )}
            {invoice.sixtyMonths && (
              <Text style={styles.paragraph}>
                {formatMoney(factorSheet.initialPayment.finalInitialPayment)}
              </Text>
            )}
          </View>
          <View style={styles.invoiceColumn}>
            <Text style={[styles.paragraph, styles.bold]}>Renta mensual</Text>
            {invoice.twelveMonths && (
              <Text style={styles.paragraph}>
                {formatMoney(factorSheet.factorInformation['12'].monthlyPayment)}
              </Text>
            )}
            {invoice.twentyFourMonths && (
              <Text style={styles.paragraph}>
                {formatMoney(factorSheet.factorInformation['24'].monthlyPayment)}
              </Text>
            )}
            {invoice.thirtySixMonths && (
              <Text style={styles.paragraph}>
                {formatMoney(factorSheet.factorInformation['36'].monthlyPayment)}
              </Text>
            )}
            {invoice.fortyEightMonths && (
              <Text style={styles.paragraph}>
                {formatMoney(factorSheet.factorInformation['48']?.monthlyPayment ?? 0)}
              </Text>
            )}
            {invoice.sixtyMonths && (
              <Text style={styles.paragraph}>
                {formatMoney(factorSheet.factorInformation['60']?.monthlyPayment ?? 0)}
              </Text>
            )}
          </View>
          <View style={styles.invoiceColumn}>
            <Text style={[styles.paragraph, styles.bold]}>Valor residual</Text>
            {invoice.twelveMonths && (
              <Text style={styles.paragraph}>
                {formatMoney(factorSheet.factorInformation['12'].residualAmount)}
              </Text>
            )}
            {invoice.twentyFourMonths && (
              <Text style={styles.paragraph}>
                {formatMoney(factorSheet.factorInformation['24'].residualAmount)}
              </Text>
            )}
            {invoice.thirtySixMonths && (
              <Text style={styles.paragraph}>
                {formatMoney(factorSheet.factorInformation['36'].residualAmount)}
              </Text>
            )}
            {invoice.fortyEightMonths && factorSheet.factorInformation['48'] ? (
              <Text style={styles.paragraph}>
                {formatMoney(factorSheet.factorInformation['48'].residualAmount)}
              </Text>
            ) : null}
            {invoice.sixtyMonths && factorSheet.factorInformation['60'] ? (
              <>
                <Text style={styles.paragraph}>
                  {formatMoney(factorSheet.factorInformation['60'].residualAmount)}
                </Text>
              </>
            ) : null}
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export default InvoicePrintable;
