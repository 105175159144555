import React from 'react';
import { Grid } from '@mui/material';
import { FlexContainer } from '../../../../../components/FlexContainer/FlexContainer';
import { PrimaryTitleStart } from '../../../../../components/PrimaryTitle/PrimaryTitle';
import { ErcoProduct } from '../../../interfaces/products';
import NoImage from '../../../../../assets/img/no-image.jpg';
import { NoPaddingButton } from '../../../../../components/NoPaddingButton/NoPaddingButton';

interface ProductCardProps {
  product: ErcoProduct;
  onClick: () => void;
}

const ProductCard = ({ product, onClick }: ProductCardProps): JSX.Element => {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <NoPaddingButton style={{ width: '100%' }} onClick={onClick}>
        <div
          style={{
            overflow: 'hidden',
            borderRadius: '20px',
            width: '100%',
            boxShadow: '0px 3px 8px rgba(0, 12, 51, 0.15)',
          }}
        >
          <img
            src={product.imageURL || NoImage}
            alt='Imagen del producto'
            style={{ width: '100%', aspectRatio: '1 / 1', objectFit: 'cover' }}
          />
          <FlexContainer
            direction='column'
            alignItems='flex-start'
            style={{ width: 'calc(100% - 3rem)', padding: '1.5rem' }}
          >
            <PrimaryTitleStart style={{ textAlign: 'center' }}>
              {product.name}
            </PrimaryTitleStart>
          </FlexContainer>
        </div>
      </NoPaddingButton>
    </Grid>
  );
};

export default ProductCard;
