import { Grid, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../../assets/img/erco.webp';
import { useCotizadorProvider } from '../context/CotizadorMainContext';
import CotizadorOptionsDropdown from './CotizadorOptionsDropdown';

const CotizadorNavbar = (): JSX.Element => {
  const { user } = useCotizadorProvider();
  const muiTheme = useTheme();
  const mobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const location = useLocation();
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: '0',
        transition: 'left 0.5s, right 0.5s',
        transitionTimingFunction: 'ease-out',
        right: '0',
        zIndex: 1000,
      }}
    >
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        style={{
          backgroundColor: 'white',
          border: '1px solid #DFE1E3',
          boxShadow: '0px 2px 10px rgba(0, 24, 102, 0.05)',
          position: 'relative',
        }}
      >
        <Grid item xs={3} />

        <Grid item xs={6} style={{ textAlign: 'center' }}>
          <Tooltip title='Ir a inicio' placement='bottom'>
            <Link
              to={{
                pathname: '/erco/home',
                search: location.search,
              }}
            >
              <img
                src={Logo}
                alt='Logo erco'
                style={{ height: mobile ? '3.5rem' : '5rem', margin: '0.5rem' }}
              />
            </Link>
          </Tooltip>
        </Grid>

        <Grid item xs={3}>
          <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
            {user ? <CotizadorOptionsDropdown /> : null}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CotizadorNavbar;
