import React from 'react';
import {
  Alert,
  AlertColor,
  CircularProgress,
  Snackbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useIdleTimer, workerTimers } from 'react-idle-timer';
import { AlertTypes } from '../../../interfaces/MainContextInitialValues';
import { mainTheme } from '../../../utils/theme/theme';
import { useCotizadorProvider } from '../context/CotizadorMainContext';
import CotizadorNavbar from './CotizadorNavbar';

function CotizadorMainLayout({ children }: { children: React.ReactNode }): JSX.Element {
  const { openAlert, setOpenAlert, alertMessage, alertType, loading, handleLogout } =
    useCotizadorProvider();

  const muiTheme = useTheme();
  const mobile = useMediaQuery(muiTheme.breakpoints.down('sm'));

  /**
   * closes alert message
   */
  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const handleOnIdle = () => {
    handleLogout();
  };

  useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    crossTab: true,
    timers: workerTimers,
  });

  const location = useLocation();

  const mapAlertTypeToSeverity = (type: AlertTypes): AlertColor => {
    switch (type) {
      case AlertTypes.Error:
        return 'error';
      case AlertTypes.Success:
        return 'success';
      case AlertTypes.Warning:
        return 'warning';
      default:
        return 'info';
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          width: '100%',
          marginLeft: '0',
          transition: 'margin-left 0.5s',
          transitionTimingFunction: 'ease-out',
        }}
      >
        {loading && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 5000,
              height: '200vh',
              backgroundColor: 'white',
            }}
          >
            <div style={{ width: '100%', height: '100vh', position: 'relative' }}>
              <CircularProgress
                variant='determinate'
                style={{
                  color: 'lightgray',
                  animationDuration: '550ms',
                  position: 'absolute',
                  top: 'calc(50% - 35px)',
                  left: 'calc(50% - 35px)',
                }}
                size={70}
                thickness={4}
                value={100}
              />
              <CircularProgress
                variant='indeterminate'
                disableShrink
                style={{
                  strokeLinecap: 'round',
                  color: mainTheme.colors.uiElements,
                  animationDuration: '550ms',
                  position: 'absolute',
                  top: 'calc(50% - 35px)',
                  left: 'calc(50% - 35px)',
                }}
                size={70}
                thickness={4}
              />
            </div>
          </div>
        )}
        <Snackbar
          open={openAlert}
          autoHideDuration={6000}
          onClose={() => {
            handleAlertClose();
          }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            variant='outlined'
            severity={mapAlertTypeToSeverity(alertType)}
            onClose={() => {
              handleAlertClose();
            }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
        {location.pathname !== '/' ? <CotizadorNavbar /> : null}
        {location.pathname !== '/' ? (
          <div style={{ width: '100%', marginTop: mobile ? '4.5rem' : '6rem' }}>
            {children}
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
}

export default CotizadorMainLayout;
