import { Dispatch, SetStateAction } from 'react';
import { AlertTypes } from '../../../interfaces/MainContextInitialValues';

export interface CotizadorUser {
  id: number;
  username: string;
  token?: string;
}

export interface CotizadorUserForm {
  email: string;
  password: string;
  confirmPasword?: string;
  recaptcha?: string;
}

export const initialCotizadorUserForm: CotizadorUserForm = {
  email: '',
  password: '',
  confirmPasword: '',
  recaptcha: '',
};

export interface CotizadorContext {
  loadInitialData: () => void;
  user?: CotizadorUser;
  token?: string;
  setToken: (token: string | undefined) => void;
  openAlert: boolean;
  alertMessage: string;
  alertType: AlertTypes;
  setUser: (user: CotizadorUser | undefined) => void;
  setOpenAlert: (openAlertMessage: boolean) => void;
  setAlertMessage: (alertMessage: string) => void;
  setAlertType: (setAlertType: AlertTypes) => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  handleLogoutError: (e: any) => void;
  handleLogout: () => void;
  initialLoad: boolean;
}
