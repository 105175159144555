import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertTypes } from '../../../interfaces/MainContextInitialValues';
import EmptyClosure from '../../../utils/closures';
import { getLocalstorageData, removeLocalstorageData } from '../../../utils/localStorage';
import {
  CotizadorContext,
  CotizadorUser,
} from '../interfaces/cotizadorContextInterfaces';

const initialState: CotizadorContext = {
  openAlert: false,
  alertMessage: '',
  alertType: AlertTypes.Success,
  loading: false,
  user: undefined,
  setUser: EmptyClosure,
  setLoading: EmptyClosure,
  setAlertMessage: EmptyClosure,
  setAlertType: EmptyClosure,
  setOpenAlert: EmptyClosure,
  token: undefined,
  handleLogout: EmptyClosure,
  initialLoad: false,
  handleLogoutError: EmptyClosure,
  setToken: EmptyClosure,
  loadInitialData: EmptyClosure,
};

const CotizadorMainContext = createContext<CotizadorContext>(initialState);

const useCotizadorProvider = (): CotizadorContext => {
  const context = useContext(CotizadorMainContext);
  if (!context) {
    throw new Error('Context is not initialized');
  }
  return context;
};

const CotizadorMainProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [user, setUser] = useState<CotizadorUser | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [alertType, setAlertType] = useState<AlertTypes>(AlertTypes.Success);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);

  const navigate = useNavigate();

  const loadInitialData = useCallback(() => {
    // Check local storage for user information and st the user state if needed
    const localStorageUser = getLocalstorageData<CotizadorUser>('COTIZADOR_INFORMATION');
    if (localStorageUser.success) {
      if (localStorageUser.data && localStorageUser.data.token) {
        setUser({ ...localStorageUser.data, token: undefined });
        setToken(localStorageUser.data.token);
      }
    }
    setInitialLoad(true);
  }, []);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  const handleLogout = useCallback(() => {
    setUser(undefined);
    setToken(undefined);
    removeLocalstorageData('COTIZADOR_INFORMATION');
    navigate('/erco/login');
  }, [navigate]);

  const handleLogoutError = useCallback(
    (e: any) => {
      const error = e as { response?: { data?: any } };
      if (
        error?.response?.data &&
        JSON.stringify(error.response.data) &&
        JSON.stringify(error.response.data).includes('Error in authentication')
      ) {
        handleLogout();
      }
    },
    [handleLogout],
  );

  return (
    <CotizadorMainContext.Provider
      value={{
        initialLoad,
        openAlert,
        alertMessage,
        alertType,
        loading,
        user,
        setUser,
        setLoading,
        setAlertMessage,
        setAlertType,
        setOpenAlert,
        token,
        handleLogout,
        handleLogoutError,
        setToken,
        loadInitialData,
      }}
    >
      {children}
    </CotizadorMainContext.Provider>
  );
};

export default CotizadorMainProvider;
export { useCotizadorProvider };
