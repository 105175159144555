export enum ProductConditions {
  unknown = '-1',
  new = '1',
  used = '2',
}

export interface ErcoProduct {
  id: number;
  accountId: number;
  is_active: boolean;
  fecha: string;
  name: string;
  imageURL: string | null;
  description: string | null;
  accesories: string | null;
  model: string | null;
  warranty: string | null;
  year: number | null;
  condition: ProductConditions;
  timeForShipping: string | null;
  price: number;
}

export interface ErcoInvoice {
  id: number;
  is_active: boolean;
  fecha: string;
  productId: number;
  discountAmount: number | null;
  discountPercentage: number | null;
  upfrontPaymentAmount: number | null;
  upfrontPaymentPercentage: number | null;
  twelveMonths: boolean;
  twentyFourMonths: boolean;
  thirtySixMonths: boolean;
  fortyEightMonths: boolean;
  sixtyMonths: boolean;
  twelveResidual: number;
  twentyFourResidual: number;
  thirtySixResidual: number;
  fortyEightResidual: number;
  sixtyResidual: number;
  customerName: string;
  customerFirstLastName: string;
  customerSecondLastName: string;
  customerEmail: string;
  customerPhone: string;
  pdfURL: string;
}

export interface FactorInformationVals {
  factor: number;
  defaultResidual: number;
  monthlyPayment: number;
  residualAmount: number;
}

export interface FactorInformation {
  12: FactorInformationVals;
  24: FactorInformationVals;
  36: FactorInformationVals;
  48?: FactorInformationVals;
  60?: FactorInformationVals;
}

export interface FactorSheet {
  discountedPrice: number;
  initialPayment: {
    commission: number;
    registrationCost: number;
    iva: number;
    finalInitialPayment: number;
  };
  amountToFinance: number;
  factorInformation: FactorInformation;
}
