import React, { SyntheticEvent, useRef, useState } from 'react';
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GoSignOut, IoMdArrowDropdown, IoPersonCircle } from 'react-icons/all';
import { mainTheme } from '../../../utils/theme/theme';
import { useCotizadorProvider } from '../context/CotizadorMainContext';

const CotizadorOptionsDropdown = (): JSX.Element => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { user, handleLogout } = useCotizadorProvider();
  const navigate = useNavigate();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current && !open && anchorRef.current) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
        style={{
          backgroundColor: '#E1E1E1',
          padding: '0.1rem',
          borderRadius: mobile ? '2rem' : '2.2rem',
          textAlign: 'left',
          marginRight: '1.5rem',
        }}
      >
        <IoPersonCircle
          style={{
            fontSize: mobile ? '1.8rem' : '2rem',
            color: mainTheme.colors.uiElements,
          }}
        />
        <IoMdArrowDropdown
          style={{
            fontSize: mobile ? '0.8rem' : '1rem',
            margin: mobile ? '0 0.5rem' : '0 0.6rem 0 0.6rem',
          }}
        />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement='bottom-end'
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            in={TransitionProps ? TransitionProps.in : undefined}
            onEnter={TransitionProps ? TransitionProps.onEnter : undefined}
            onExited={TransitionProps ? TransitionProps.onExited : undefined}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={
                  handleClose as unknown as (event: MouseEvent | TouchEvent) => void
                }
              >
                <MenuList
                  autoFocusItem={open}
                  aria-labelledby='composition-button'
                  onKeyDown={handleListKeyDown}
                >
                  {user && (
                    <MenuItem
                      onClick={() => {
                        handleLogout();
                        navigate({
                          pathname: `/erco/login`,
                        });
                      }}
                    >
                      <GoSignOut
                        style={{
                          color: mainTheme.colors.navbarItems,
                          marginRight: '0.5rem',
                        }}
                      />
                      Cerrar sesión
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default CotizadorOptionsDropdown;
