import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import axios from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import { BsCheckCircle, BsCircle } from 'react-icons/all';
import Recaptcha from 'react-recaptcha';
import { AlertTypes } from '../../../../interfaces/MainContextInitialValues';
import { saveLocalstorageData } from '../../../../utils/localStorage';
import CenteredForm from '../../../../index.styled';
import { PageContainer } from '../../../Register/Register.styled';
import {
  PrimaryTitle,
  PrimaryTitleStart,
} from '../../../../components/PrimaryTitle/PrimaryTitle';
import { PrimaryDescription } from '../../../../components/PrimaryDescription/PrimaryDescription';
import { CMTextfield } from '../../../../components/Forms/CMTextfield';
import PasswordTextfield from '../../../../components/Forms/PasswordTextfield';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import { CRUDModes } from '../../../../interfaces/CRUD';
import { useCotizadorProvider } from '../../context/CotizadorMainContext';
import {
  CotizadorUser,
  CotizadorUserForm,
  initialCotizadorUserForm,
} from '../../interfaces/cotizadorContextInterfaces';

const CotizadorAccountForm = (): JSX.Element => {
  const {
    user,
    setOpenAlert,
    setAlertMessage,
    setAlertType,
    setUser,
    setLoading,
    setToken,
  } = useCotizadorProvider();
  const [formValues] = useState<CotizadorUserForm>(initialCotizadorUserForm);
  const [passwordFormatActive, setPasswordFormatActive] = useState<boolean>(false);
  const [passwordLenght, setPasswordLenght] = useState<boolean>(false);
  const [lowerCase, setLowerCase] = useState<boolean>(false);
  const [upperCase, setUpperCase] = useState<boolean>(false);
  const [number, setNumber] = useState<boolean>(false);
  const [symbol, setSymbol] = useState<boolean>(false);
  const [samePassword, setSamePassword] = useState<boolean>(false);

  const navigate = useNavigate();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  useEffect(() => {
    if (user) {
      navigate('/erco/home');
    }
  }, [navigate, user]);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Escriba un email válido')
      .required('El email es un campo obligatorio'),
    password: Yup.string()
      .required('La contraseña es un campo obligatorio')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Debe contener 8 caracteres, una mayuscula, una minuscula, un número y un caracter especial como mínimo',
      ),
    confirmPasword: Yup.string()
      .required('La contraseña es un campo obligatorio')
      .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir'),
    recaptcha: Yup.string().required('Es necesario llenar el captcha'),
  });

  const validatePasswordFormat = (
    value: string,
  ): {
    lowerCaseResult: boolean;
    upperCaseResult: boolean;
    numberResult: boolean;
    symbolResult: boolean;
  } => {
    const passwordLenghtResult = value.length >= 8;
    const lowerCaseResult = /.*[a-z].*/.test(value);
    const upperCaseResult = /.*[A-Z].*/.test(value);
    const numberResult = /\d/.test(value);
    const symbolResult = /[*@!#%&()^~{}]+/.test(value);
    setPasswordLenght(passwordLenghtResult);
    setLowerCase(lowerCaseResult);
    setUpperCase(upperCaseResult);
    setNumber(numberResult);
    setSymbol(symbolResult);
    return {
      lowerCaseResult,
      upperCaseResult,
      numberResult,
      symbolResult,
    };
  };

  const validateSamePassword = (
    value: string,
    passwordValue: string,
  ): {
    samePasswordResult: boolean;
  } => {
    const samePasswordResult = value === passwordValue;
    setSamePassword(samePasswordResult);
    return {
      samePasswordResult,
    };
  };

  const formik = useFormik<CotizadorUserForm>({
    initialValues: formValues,
    validationSchema,
    enableReinitialize: true,
    /**
     * @desc saves a new user in the database
     * @param registerValues
     */
    onSubmit: async (registerValues: CotizadorUserForm) => {
      try {
        const payload = { ...registerValues };
        delete payload.confirmPasword;
        delete payload.recaptcha;

        setLoading(true);
        const registerResult = await axios.post<{
          user: CotizadorUser;
          token: string;
        }>(`${process.env.REACT_APP_SERVER_URL}/erco/new-account`, payload);
        if (registerResult.status === 200) {
          formik.resetForm();
          setAlertType(AlertTypes.Success);
          setAlertMessage(`Usuario creado con exito`);
          setOpenAlert(true);
          const newUser = {
            ...registerResult.data.user,
            token: registerResult.data.token,
          };
          saveLocalstorageData('COTIZADOR_INFORMATION', newUser);
          setToken(registerResult.data.token);
          setUser(newUser);
        }
        setLoading(false);
      } catch (e: any) {
        if (JSON.stringify(e.response.data)) {
          if (e.response.data.code && e.response.data.code === 'ER_DUP_ENTRY') {
            setLoading(false);
            setAlertType(AlertTypes.Error);
            setAlertMessage(
              `Error al iniciar sesión: Este correo ya se encuentra en uso, utilice un correo distinto`,
            );
            setOpenAlert(true);
          } else {
            setLoading(false);
            setAlertType(AlertTypes.Error);
            setAlertMessage(
              `Error al iniciar sesión: ${JSON.stringify(e.response.data)} `,
            );
            setOpenAlert(true);
          }
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error en el servidor: ${e}`);
          setOpenAlert(true);
        }
      }
    },
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <PageContainer style={{ paddingTop: '1.5rem' }}>
      <Grid container direction='row' justifyContent='center'>
        <Grid item xs={12} md={5}>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              exit={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3 }}
              key='login'
            >
              <CenteredForm onSubmit={formik.handleSubmit}>
                <>
                  <PrimaryTitle style={{ textAlign: 'center', marginTop: '2rem' }}>
                    Registro al módulo de cotizaciones
                  </PrimaryTitle>
                  <PrimaryDescription mobile={mobile} style={{ marginBottom: '2.5rem' }}>
                    Antes de comenzar primero debes crear una cuenta
                  </PrimaryDescription>
                </>

                <PrimaryTitleStart style={{ margin: '1rem 0' }}>
                  Datos de la cuenta
                </PrimaryTitleStart>
                <CMTextfield
                  id='outlined-basic'
                  label='Email'
                  variant='outlined'
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  name='email'
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  onChange={formik.handleChange}
                />
                <>
                  <PasswordTextfield
                    id='outlined-basic-password'
                    label='Contraseña'
                    variant='outlined'
                    value={formik.values.password}
                    name='password'
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    onChange={(e) => {
                      validatePasswordFormat(e.target.value);
                      formik.handleChange(e);
                    }}
                    onFocus={() => setPasswordFormatActive(true)}
                  />
                  <PasswordTextfield
                    id='outlined-basic-password-1'
                    label='Confirmar contraseña'
                    variant='outlined'
                    value={formik.values.confirmPasword}
                    name='confirmPasword'
                    error={
                      formik.touched.confirmPasword &&
                      Boolean(formik.errors.confirmPasword)
                    }
                    helperText={
                      formik.touched.confirmPasword && formik.errors.confirmPasword
                    }
                    onChange={(e) => {
                      validateSamePassword(
                        e.target.value,
                        formik.values.password as string,
                      );
                      formik.handleChange(e);
                    }}
                  />
                  {passwordFormatActive && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      exit={{ opacity: 0, height: 0 }}
                      animate={{
                        opacity: 1,
                        height: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='bodyMassIndex-div'
                      style={{
                        textAlign: 'start',
                        width: '100%',
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        borderRadius: '12px',
                        backgroundColor: '#fafafa',
                        padding: '1rem',
                        marginBottom: '1rem',
                      }}
                    >
                      <div>
                        <p style={{ marginTop: '0', marginBottom: '.8rem' }}>
                          {' '}
                          La contraseña debe tener minimo{' '}
                        </p>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {passwordLenght ? (
                            <BsCheckCircle
                              style={{
                                color: 'green',
                                marginRight: '2%',
                              }}
                            />
                          ) : (
                            <BsCircle
                              style={{
                                color: 'red',
                                marginRight: '2%',
                              }}
                            />
                          )}
                          <p
                            style={{
                              color: passwordLenght ? 'green' : 'red',
                              marginTop: '.5rem',
                              marginBottom: '.5rem',
                            }}
                          >
                            8 caracteres{' '}
                          </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {lowerCase ? (
                            <BsCheckCircle
                              style={{
                                color: 'green',
                                marginRight: '2%',
                              }}
                            />
                          ) : (
                            <BsCircle
                              style={{
                                color: 'red',
                                marginRight: '2%',
                              }}
                            />
                          )}
                          <p
                            style={{
                              color: lowerCase ? 'green' : 'red',
                              marginTop: '.5rem',
                              marginBottom: '.5rem',
                            }}
                          >
                            Una minuscula{' '}
                          </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {upperCase ? (
                            <BsCheckCircle
                              style={{
                                color: 'green',
                                marginRight: '2%',
                              }}
                            />
                          ) : (
                            <BsCircle
                              style={{
                                color: 'red',
                                marginRight: '2%',
                              }}
                            />
                          )}
                          <p
                            style={{
                              color: upperCase ? 'green' : 'red',
                              marginTop: '.5rem',
                              marginBottom: '.5rem',
                            }}
                          >
                            Una mayuscula{' '}
                          </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {number ? (
                            <BsCheckCircle
                              style={{
                                color: 'green',
                                marginRight: '2%',
                              }}
                            />
                          ) : (
                            <BsCircle
                              style={{
                                color: 'red',
                                marginRight: '2%',
                              }}
                            />
                          )}
                          <p
                            style={{
                              color: number ? 'green' : 'red',
                              marginTop: '.5rem',
                              marginBottom: '.5rem',
                            }}
                          >
                            Un número{' '}
                          </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {symbol ? (
                            <BsCheckCircle
                              style={{
                                color: 'green',
                                marginRight: '2%',
                              }}
                            />
                          ) : (
                            <BsCircle
                              style={{
                                color: 'red',
                                marginRight: '2%',
                              }}
                            />
                          )}
                          <p
                            style={{
                              color: symbol ? 'green' : 'red',
                              marginTop: '.5rem',
                              marginBottom: '.5rem',
                            }}
                          >
                            Un simbolo{' '}
                          </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {samePassword ? (
                            <BsCheckCircle
                              style={{
                                color: 'green',
                                marginRight: '2%',
                              }}
                            />
                          ) : (
                            <BsCircle
                              style={{
                                color: 'red',
                                marginRight: '2%',
                              }}
                            />
                          )}
                          <p
                            style={{
                              color: samePassword ? 'green' : 'red',
                              marginTop: '.5rem',
                              marginBottom: '.5rem',
                            }}
                          >
                            Las contraseñas deben coincidir{' '}
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </>
                <Recaptcha
                  hl='es'
                  sitekey='6Lepqn8eAAAAAEKyrn_qs960q8KQT_WPdGH0XPiS'
                  verifyCallback={(response) => {
                    formik.setFieldValue('recaptcha', response);
                  }}
                />

                <PrimaryButton
                  loading={false}
                  type='submit'
                  style={{ width: '100%', marginTop: '1rem' }}
                >
                  Registrarse
                </PrimaryButton>
              </CenteredForm>
            </motion.div>
          </AnimatePresence>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

CotizadorAccountForm.defaultProps = {
  values: undefined,
  mode: CRUDModes.Create,
  onSuccess: () => {},
};

export default CotizadorAccountForm;
